import { Stack, Box } from '@mui/material';
import { mdiTagOutline } from '@mdi/js';

import { IExtraCategoryValueFragment } from 'graphql/types';
import { InfoRow } from 'common/components/InfoRow';
import { Icon } from 'common/components/Icon';
import { groupCategoryValuesByCategoryName } from 'organisation/utils/extraCategory';

interface IProps {
    extraCategoryValues: IExtraCategoryValueFragment[];
}

export const ExtraCategoryInfo = ({ extraCategoryValues }: IProps) => {
    if (!extraCategoryValues.length) return null;

    const categoryGroups =
        groupCategoryValuesByCategoryName(extraCategoryValues);

    return (
        <Stack spacing={2}>
            {Object.entries(categoryGroups).map(
                ([categoryName, categoryValues]) => (
                    <InfoRow description={categoryName} key={categoryName}>
                        <Stack>
                            {categoryValues.map((value) => (
                                <Box
                                    key={value.id + value.label}
                                    sx={{ display: 'flex' }}
                                >
                                    <Box
                                        sx={{
                                            py: 1,
                                            px: 1.25,
                                            mt: '-7px',
                                            display: 'inline-flex',
                                            color: 'inherit',
                                        }}
                                    >
                                        <Icon
                                            path={mdiTagOutline}
                                            size="2rem"
                                        />
                                    </Box>
                                    <Box>{value.label}</Box>
                                </Box>
                            ))}
                        </Stack>
                    </InfoRow>
                )
            )}
        </Stack>
    );
};

import { MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import { mdiClose, mdiPencil } from '@mdi/js';

import { DrawerActionDropdown, PageDrawer } from 'common/components/PageDrawer';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import {
    useDeleteOfferEventMutation,
    useDeleteOfferMutation,
    useOfferEventQuery,
    IOfferEventType,
    IContentTypeValue,
    useCopyOfferEventMutation,
    useCopyOfferMutation,
} from 'graphql/types';
import { IGlobalDrawerType } from 'common/types';
import { ApolloError } from 'common/components/ApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { Loader } from 'common/components/Loader';
import { isUUID } from 'common/utils/isUUID';
import { getUrl } from 'route/utils/getUrl';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { PageTitle } from 'common/components/PageTitle';
import { useApolloError } from 'common/hooks/useApolloError';
import { BackgroundImg } from 'common/components/Img';
import { AuthorButton } from 'user/components/AuthorButton';
import { OfferPermissionsContext } from 'offer/contexts';
import { OfferEventPermissionsContext } from 'offer/contexts/OfferPermissionsContext';
import { OfferEventDetail } from 'offer/components/OfferEventDetail';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { blockGlobalDrawer } from 'common/components/GlobalDrawerProvider/GlobalDrawerList';
import { CancelOfferEventButton } from 'offer/components/CancelOfferEventButton';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';

interface IProps extends IPageDrawerProps {
    includeOfferFields?: boolean;
    isOfferDetailDrawer?: boolean;
    loading?: boolean;
    offerEventId?: string;
    offerLoading?: boolean;
    onClose?: () => void;
}

export const OfferEventDetailDrawer = ({
    includeOfferFields,
    isOfferDetailDrawer,
    loading,
    offerEventId,
    offerLoading,
    onClose,
    ...other
}: IProps) => {
    const { t: translate } = useTranslation();
    const location = useLocation();
    const { openGlobalDrawer } = useGlobalDrawer();
    const routeQuery = useRouteQuery();
    const [displaySnackbar] = useSnackbar();
    const isOfferEventDetailDrawerPage = useHashMatch(
        'OFFER_EVENT_DETAIL_DRAWER'
    );
    const client = useApolloClient();

    const offerEventIdParam = isOfferEventDetailDrawerPage
        ? routeQuery.get('id')
        : undefined;

    const drawerRef = useRef<HTMLDivElement | null>(null);

    const eventId = offerEventId || offerEventIdParam || '';
    const isEventDetailPage = !!useRouteMatch('OFFER_EVENT_DETAIL_DRAWER');
    const onBlockGlobalDrawer = useReactiveVar(blockGlobalDrawer);

    const {
        data,
        loading: offerEventLoading,
        error,
    } = useOfferEventQuery({
        variables: { id: eventId },
        skip: !eventId || loading,
        notifyOnNetworkStatusChange: true,
    });

    const [copyOfferEvent, { loading: copyOfferEventLoading }] =
        useCopyOfferEventMutation();
    const [deleteOfferEvent, { loading: deleteOfferEventLoading }] =
        useDeleteOfferEventMutation();

    const [copyOffer, { loading: copyOfferLoading }] = useCopyOfferMutation();
    const [deleteOffer, { loading: deleteOfferLoading }] =
        useDeleteOfferMutation();

    const [showCopyDialog, setShowCopyDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [edit, setEdit] = useState(false);
    const { showApolloError } = useApolloError();

    const { offerEvent } = data || {};

    const { offer } = offerEvent || {};
    const offerPermissions = offer?.permissions
        ? JSON.parse(offer?.permissions)
        : undefined;

    const { canUpdate = false } = offerPermissions || {};

    const offerEventPermissions = offerEvent?.permissions
        ? JSON.parse(offerEvent?.permissions)
        : undefined;

    if (
        isEventDetailPage &&
        (!isUUID(eventId) || (!offerEventLoading && !offerEvent))
    ) {
        return <Navigate to={getUrl('PAGE_NOT_FOUND')} />;
    }

    const handleClose = (cancelEdit?: boolean) => {
        // Runs on exit page alert actions
        if (!!onBlockGlobalDrawer && cancelEdit) {
            setEdit(false);

            onClose?.();

            return;
        }

        onClose?.();

        if (!!onBlockGlobalDrawer) return;

        setEdit(false);
    };

    const clearCacheForOfferLists = () => {
        client.cache.evict({ fieldName: 'offerEvents' });
        client.cache.evict({ fieldName: 'offers' });
        client.cache.evict({ fieldName: 'paginatedOfferEvents' });
        client.cache.gc();
    };

    const handleCopyOfferEvent = async () => {
        try {
            if (isOfferDetailDrawer) {
                if (!offer?.id) return;

                const response = await copyOffer({
                    variables: { id: offer.id },
                });

                const newOffer = response.data?.copyOffer?.offer;

                if (newOffer) {
                    openGlobalDrawer(
                        {
                            type: IGlobalDrawerType.Offer,
                            itemId: newOffer.id,
                        },
                        location
                    );
                }
            } else {
                const response = await copyOfferEvent({
                    variables: { id: eventId },
                });
                const newOfferEvent = response.data?.copyOfferEvent?.offerEvent;

                if (newOfferEvent) {
                    openGlobalDrawer(
                        {
                            type: IGlobalDrawerType.OfferEvent,
                            itemId: newOfferEvent.id,
                        },
                        location
                    );
                }
            }
        } catch (error) {
            showApolloError(error);

            return;
        }

        clearCacheForOfferLists();

        setShowCopyDialog(false);

        displaySnackbar(translate('offerActionSuccess.copy'), {
            variant: 'success',
        });
    };

    const handleDeleteOfferEvent = async () => {
        if (isOfferDetailDrawer) {
            if (!offer?.id) return;

            try {
                await deleteOffer({
                    variables: { id: offer.id },
                    update: (cache) => {
                        // Remove offer from cache
                        cache.evict({ id: `Offer:${offer.id}` });
                        cache.gc();
                    },
                });

                displaySnackbar(translate('offerActionSuccess.delete'), {
                    variant: 'success',
                });
            } catch (error) {
                showApolloError(error);
            }
        } else {
            if (!eventId) return;

            try {
                await deleteOfferEvent({
                    variables: { id: eventId },
                    update: (cache) => {
                        // Remove item from cache
                        cache.evict({ id: `OfferEvent:${eventId}` });
                        cache.gc();
                    },
                });

                displaySnackbar(translate('offerEventActionSuccess.delete'), {
                    variant: 'success',
                });
            } catch (error) {
                showApolloError(error);
            }
        }

        clearCacheForOfferLists();

        setShowDeleteDialog(false);
        setEdit(false);

        onClose?.();
    };

    const handleEdit = () => {
        // Timeout to avoid translation being displayed while menu is still closing
        setTimeout(() => {
            setEdit(!edit);
        }, 100);
    };

    const isLoading = loading || offerEventLoading || offerLoading;
    const isElearning = offerEvent?.type === IOfferEventType.Elearning;
    const isMeeting =
        offerEvent?.type === IOfferEventType.Meeting ||
        offerEvent?.type === IOfferEventType.Webinar;
    const headerImage = !edit && isOfferDetailDrawer && offer?.image?.url;
    const showCancelEventButton =
        !loading && !!offerEvent && canUpdate && isMeeting;
    const showAuthorButton = !!(
        !isLoading &&
        canUpdate &&
        !!offer?.id &&
        isOfferDetailDrawer &&
        !isElearning
    );

    const drawerActions = (
        <Stack direction="row" spacing={1}>
            {showCancelEventButton && (
                <CancelOfferEventButton offerEvent={offerEvent} />
            )}

            {showAuthorButton && (
                <AuthorButton
                    contentType={IContentTypeValue.Offer}
                    objectId={offer.id}
                />
            )}

            {!isLoading && canUpdate ? (
                !edit ? (
                    !isElearning ? (
                        <DrawerActionDropdown>
                            <MenuItem onClick={handleEdit}>
                                {translate('edit')}
                            </MenuItem>

                            <MenuItem onClick={() => setShowCopyDialog(true)}>
                                {translate('copy')}
                            </MenuItem>
                        </DrawerActionDropdown>
                    ) : (
                        <Button
                            size="small"
                            startIcon={<Icon path={mdiPencil} size="1.9rem" />}
                            variant="outlined"
                            onClick={handleEdit}
                        >
                            {translate('edit')}
                        </Button>
                    )
                ) : (
                    <Button
                        size="small"
                        startIcon={<Icon path={mdiClose} size="1.9rem" />}
                        variant="outlined"
                        onClick={handleEdit}
                    >
                        {translate('cancelEdit')}
                    </Button>
                )
            ) : undefined}
        </Stack>
    );

    return (
        <OfferPermissionsContext.Provider value={offerPermissions}>
            <OfferEventPermissionsContext.Provider
                value={offerEventPermissions}
            >
                <PageDrawer
                    actions={drawerActions}
                    disableClose={deleteOfferLoading || deleteOfferEventLoading}
                    headerProps={{ floating: !!headerImage }}
                    open={!!eventId}
                    ref={drawerRef}
                    onClose={() => handleClose()}
                    {...other}
                >
                    {error && <ApolloError error={error} />}

                    <PageTitle
                        mixpanelTitle={`${
                            edit ? 'Update event' : 'Event'
                        } - Offers`}
                    >
                        {`${translate('offers')} - ${translate(
                            `offer.${edit ? 'updateEvent' : 'event'}`
                        )}`}
                    </PageTitle>

                    {isLoading && <Loader />}

                    <ConfirmDialog
                        color="error"
                        confirmText={translate('delete')}
                        loading={deleteOfferLoading || deleteOfferEventLoading}
                        open={showDeleteDialog}
                        title={translate(
                            isOfferDetailDrawer
                                ? 'deleteOfferMessage.title'
                                : 'deleteOfferEvent.title'
                        )}
                        onCancel={() => {
                            setShowDeleteDialog(false);
                        }}
                        onClose={() => setShowDeleteDialog(false)}
                        onConfirm={handleDeleteOfferEvent}
                    >
                        {translate(
                            isOfferDetailDrawer
                                ? 'deleteOfferMessage.text'
                                : 'deleteOfferEvent.text'
                        )}
                    </ConfirmDialog>

                    <ConfirmDialog
                        confirmText={translate('copy')}
                        loading={copyOfferEventLoading || copyOfferLoading}
                        open={showCopyDialog}
                        title={translate(
                            isOfferDetailDrawer
                                ? 'copyOfferDialog.title'
                                : 'copyOfferEventDialog.title'
                        )}
                        onCancel={() => {
                            setShowCopyDialog(false);
                        }}
                        onClose={() => setShowCopyDialog(false)}
                        onConfirm={handleCopyOfferEvent}
                    >
                        {translate(
                            isOfferDetailDrawer
                                ? 'copyOfferDialog.text'
                                : 'copyOfferEventDialog.text'
                        )}
                    </ConfirmDialog>

                    {offerEvent && !isLoading && (
                        <>
                            {headerImage && (
                                <BackgroundImg
                                    src={headerImage}
                                    sx={{ minHeight: '250px' }}
                                />
                            )}

                            <OfferEventDetail
                                drawerRef={drawerRef}
                                edit={edit}
                                includeOfferFields={includeOfferFields}
                                isOfferDetailDrawer={isOfferDetailDrawer}
                                offerEvent={offerEvent}
                                onCancelEdit={() => {
                                    setEdit(false);
                                }}
                                onClose={handleClose}
                                onDelete={() => setShowDeleteDialog(true)}
                            />
                        </>
                    )}
                </PageDrawer>
            </OfferEventPermissionsContext.Provider>
        </OfferPermissionsContext.Provider>
    );
};
